import { RouteRecordRaw } from 'vue-router'

export const personalContactsRoutes: RouteRecordRaw[] = [
  {
    path: '/account/:id/contacts',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: 'personal',
        name: 'personal-contacts-list',
        component: () =>
          import('@/modules/personal-contacts/views/PersonalList.vue'),
      },
      {
        path: 'personal/filter',
        name: 'personal-contacts-filter',
        component: () =>
          import('@/modules/personal-contacts/views/PersonalFilter.vue'),
      },
      {
        path: 'personal/:contactId/edit',
        name: 'personal-edit-personal-data',
        component: () =>
          import(
            '@/modules/personal-contacts/views/PersonalEditPersonalData.vue'
          ),
      },
      {
        path: 'personal/:contactId/edit-professional-data',
        name: 'personal-edit-professional-data',
        component: () =>
          import(
            '@/modules/personal-contacts/views/PersonalEditProfessionalData.vue'
          ),
      },
      {
        path: 'personal/:contactId/delete-professional-data/:institutionId',
        name: 'delete-professional-data',
        component: () =>
          import('@/modules/personal-contacts/views/PersonalDeleteData.vue'),
      },
    ],
  },
]
