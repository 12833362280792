<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { Address } from '@/types/address'
import { Phone } from '@/types/phone'
import { Email } from '@/types/email'
import favoriteIcon from '@/assets/icons/green/favorite.svg'
import { CorporateWithContactData } from '@/types/corporative'

interface IDetailDataSection {
  title: string
  icon: string
  field: 'addresses' | 'emails' | 'phones' | 'about'
  emptyState: string
}
const { t } = useI18n()
const props = defineProps<{
  data: IDetailDataSection
  corporativeAccount: Partial<CorporateWithContactData>
}>()

const { data, corporativeAccount } = toRefs(props)

const { icon, title, field, emptyState } = data.value
const computedTitle = computed(() => t(title))

const isStringOnlyField = computed(() => field == 'about')

const content = computed(() => {
  const getLabel = (
    field: 'phones' | 'emails' | 'addresses',
    item: Address | Phone | Email
  ) => {
    const defaultLabelForMainField = {
      phones: 'corporate.detail.defaultMainPhoneLabel',
      emails: 'corporate.detail.defaultMainEmailLabel',
      addresses: 'corporate.detail.defaultMainAdressLabel',
    }
    const defaultLabelForSecondaryField = {
      phones: 'corporate.detail.defaultSecondaryPhoneLabel',
      emails: 'corporate.detail.defaultSecondaryEmailLabel',
      addresses: 'corporate.detail.defaultSecondaryAdressLabel',
    }
    if (!item.label || item.label.includes('label'))
      return item.main
        ? t(defaultLabelForMainField[field])
        : t(defaultLabelForSecondaryField[field])
    return item.label
  }
  if (!corporativeAccount.value) return ''
  const content = corporativeAccount.value[field]

  if (typeof content == 'string') return content

  if (Array.isArray(content) && content.length === 0) {
    return t(emptyState)
  }
  return (
    (content as (Address | Phone | Email)[])?.map(
      (item: Address | Phone | Email) => ({
        label: getLabel(field as 'addresses' | 'emails' | 'phones', item),
        main: item.main,
        value: getItemValueByField(
          item,
          field as 'addresses' | 'emails' | 'phones'
        ),
      })
    ) || []
  )
})

function getItemValueByField(
  item: Address | Phone | Email,
  field: 'addresses' | 'emails' | 'phones'
) {
  if (field == 'addresses') {
    const addressItem = item as Address
    const getLineOrEmptyQuotes = (line: string | null) =>
      line ? line + '\n' : ''

    const addressLines = [
      addressItem.line1,
      addressItem.line2,
      addressItem.line3,
    ].reduce((acc: string, el?: string | null) => {
      if (el && el.trim() !== '') {
        return acc + getLineOrEmptyQuotes(String(el))
      }
      return acc
    }, '')

    const finalLine = [
      addressItem.postalCode,
      addressItem.cityName && addressItem.stateCode
        ? `${addressItem.cityName} - ${addressItem.stateCode}`
        : '',
      addressItem.countryName,
    ]
      .filter((e) => !!e)
      .reduce((acc, el, index, array) => {
        if (index == array.length - 1) return acc + String(el)
        return acc + String(el) + "<span class='text-green'> | </span>"
      }, '')

    return `${addressLines}${finalLine}`
  } else if (field == 'phones') return (item as Phone).number
  else return (item as Email).email
}
</script>
<template>
  <div class="my-4">
    <div class="grid grid-cols-12">
      <div class="col-span-1 ml-2 mt-1">
        <img class="max-h-5 max-w-6" :src="icon" alt="" />
      </div>

      <div class="col-span-10 px-4">
        <h6 class="text-base font-bold">{{ computedTitle }}</h6>
      </div>
    </div>
    <div class="mt-2 grid grid-cols-12">
      <div v-if="content.length" class="col-span-1">
        <img
          v-if="typeof content !== 'string'"
          :src="favoriteIcon"
          alt=""
          class="ml-2 mt-2 w-full max-w-6"
        />
      </div>
      <div
        v-if="content == ''"
        class="col-span-10 py-2 pl-[67px] text-base font-light"
      >
        {{ $t(emptyState) }}
      </div>
      <div
        class="col-span-10 px-4"
        :class="!isStringOnlyField && 'border-b-2 border-grey-5'"
      >
        <div class="flex flex-col">
          <div v-if="typeof content == 'string'" class="text-base">
            {{ content }}
          </div>
          <div
            v-for="item in content"
            v-else
            :key="item.value"
            class="mb-4 flex flex-col gap-1"
          >
            <h6 class="overflow-hidden break-words text-sm font-light italic">
              {{ item?.label }}
            </h6>
            <p
              class="overflow-hidden whitespace-pre-line break-words text-sm font-bold"
              v-html="item.value"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
