import { City, Country, State } from '@/types/address'
import { CRUDService } from '@/services/CRUD'

export class AddressService extends CRUDService {
  static async getCountries() {
    return await this.handlePromise<Country[]>(
      this.api.get<Country[]>(`/address/countries`)
    )
  }
  static async getCountryStates(countryCode: string | string[]) {
    return await this.handlePromise<State[]>(
      this.api.get<State[]>(
        `/address/states?countryCode=${Array.isArray(countryCode) ? countryCode.join(',') : countryCode}`
      )
    )
  }
  static async getStateCities(stateCode: string | string[]) {
    return await this.handlePromise<City[]>(
      this.api.get<City[]>(
        `/address/cities?stateCode=${Array.isArray(stateCode) ? stateCode.join(',') : stateCode}`
      )
    )
  }
}
