<script setup lang="ts">
import favoriteGreenIcon from '@/assets/icons/green/favorite.svg'
import EditDataSectionItemMenu from '@/components/EditDataSectionItemMenu.vue'
import { FieldArray, FieldEntry } from 'vee-validate'
import Dialog from '@/components/Dialog.vue'
import { reactive } from 'vue'
import { ref } from 'vue'

type EditDataSectionType = {
  title: string
  actionLabel: string
  section: string
  defaultLabel: string
  defaultItemValue: unknown
}

const props = defineProps<EditDataSectionType>()

const { title, actionLabel } = props

const modals = reactive({
  editLabel: false,
})

const selectedField = ref<number>()
const labelBeingEdited = ref(props.defaultLabel)

const emit = defineEmits(['edit-label'])

function openEditLabelDialog(field: FieldEntry, idx: number) {
  modals.editLabel = true

  labelBeingEdited.value =
    (field.value as object)['label' as keyof object] ?? props.defaultLabel
  selectedField.value = idx
}
function saveLabel() {
  emit('edit-label', {
    idx: selectedField.value,
    label: labelBeingEdited.value,
  })
  selectedField.value = undefined
  labelBeingEdited.value = ''
  modals.editLabel = false
}
</script>
<template>
  <FieldArray v-slot="{ fields, push, remove, move }" :name="props.section">
    <div class="mb-3 flex items-center justify-between">
      <h3 class="text-xl font-bold">{{ title }}</h3>
      <Button
        :id="`${section}-add-new-item-button`"
        @click="() => push(defaultItemValue || {})"
        >{{ actionLabel }}</Button
      >
    </div>
    <div
      v-for="(field, idx) in fields"
      :id="`edit-menu-${section}-${idx}`"
      :key="field.key"
      class="mb-1"
    >
      <div class="items-top my-2 flex w-full items-center justify-between">
        <p class="w-[85%] overflow-hidden text-ellipsis font-bold">
          {{
            (field.value as object)['label' as keyof object] ||
            `${props.defaultLabel} ${idx > 0 ? idx + 1 : ''}`
          }}
        </p>
        <img v-if="idx == 0" :src="favoriteGreenIcon" class="ml-2" />
        <EditDataSectionItemMenu
          :id="idx"
          :section="props.section"
          :move="move"
          :push="push"
          :remove="
            () => {
              remove(idx)
              if (idx === 0 && fields.length == 0) push(defaultItemValue || {})
            }
          "
          :field-length="fields.length"
          @edit-label="openEditLabelDialog(field, idx)"
        />
      </div>

      <slot :idx="idx" :field="field"></slot>
    </div>
  </FieldArray>
  <Dialog
    :open="modals.editLabel"
    :title="$t('corporate.edit.editLabelTitle')"
    @close="modals.editLabel = false"
  >
    <div class="my-4 w-full">
      <Input
        id="edit-label-dialog-input"
        v-model="labelBeingEdited"
        :label="$t('corporate.edit.editLabelField')"
      />
    </div>
    <template #actions>
      <Button
        id="edit-label-dialog-button"
        type="button"
        class="mb-5 w-44"
        @click="saveLabel"
        >{{ $t('general.save') }}</Button
      >
    </template>
  </Dialog>
</template>
