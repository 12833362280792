import { RouteRecordRaw } from 'vue-router'

export const customListsRoutes: RouteRecordRaw[] = [
  {
    path: '/account/:id/contacts',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: 'custom-lists',
        name: 'custom-lists',
        component: () => import('@/modules/custom-lists/views/CustomLists.vue'),
      },
    ],
  },
]
