export const requiredFieldsRoutes = [
  {
    path: '/account/:id/contacts/required-fields',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'custom-fields-settings',
        component: () => import('./views/RequiredFieldsSettings.vue'),
      },
    ],
  },
]
