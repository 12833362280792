import { CRUDService } from '@/services/CRUD'
import { ICRUDService } from '@/services/types'
import {
  Role,
  RoleRequestParams,
  Permissions,
} from '@/modules/member/types/role'
import { FormError } from '@/types/error'

export class RoleService extends CRUDService implements ICRUDService<Role> {
  static async createRole(id: number, payload: Partial<Role>) {
    return await this.handlePromise<Role, FormError>(
      this.api.post(`/company/${id}/permission/preset`, payload)
    )
  }
  static async listPermissions() {
    return await this.handlePromise(
      this.api.get<Permissions>('/company/permission')
    )
  }

  static async listRoles({
    id,
    sort = '',
    size = '20',
    page = 0,
    searchTerm = '',
  }: RoleRequestParams) {
    return await this.handlePromise<Role[]>(
      this.api.get<Role[]>(
        `/company/${id}/permission/preset?sort=${sort}&size=${size}&page=${page}&searchTerm=${searchTerm}`
      )
    )
  }
  static async getRole(id: string, presetId: string) {
    return await this.handlePromise(
      this.api.get<Role>(`/company/${id}/permission/preset/${presetId}`)
    )
  }

  static async updateRole(id: number, payload: Partial<Role>) {
    return await this.handlePromise<Role, FormError>(
      this.api.put(`/company/${id}/permission/preset`, payload)
    )
  }

  static async deleteRole(id: string, roleId: string) {
    return await this.handlePromise(
      this.api.delete<Role>(`/company/${id}/permission/preset/${roleId}`)
    )
  }
}
