import { RouteRecordRaw } from 'vue-router'
import { personalContactsRoutes } from '@/modules/personal-contacts/routes'
import { corporativeContactsRoutes } from '@/modules/corporative-contacts/routes'
import { customListsRoutes } from '@/modules/custom-lists/routes'
import { requiredFieldsRoutes } from '@/modules/required-fields/routes'

export const contactsRoutes: RouteRecordRaw[] = [
  ...personalContactsRoutes,
  ...corporativeContactsRoutes,
  ...customListsRoutes,
  ...requiredFieldsRoutes,
  {
    path: '/account/:id/contacts',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: 'custom-table',
        name: 'contacts-manage-table',
        component: () => import('./views/ManageTableColumns.vue'),
      },
    ],
  },
]
