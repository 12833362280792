import { RouteRecordRaw } from 'vue-router'
import ErrorPage from '@/views/pageError.vue'
export const globalRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('../layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () =>
          import(
            /* webpackChunkName: "panel" */ '../views/Panel/PanelIndex.vue'
          ),
      },
      {
        path: ':pathMatch(.*)*',
        name: 'not-found',
        redirect: {
          name: 'home',
        },
      },
    ],
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
  },
]
