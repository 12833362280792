import { CRUDService } from '@/services/CRUD'
import { ICRUDService, QueryParams } from '@/services/types'
import { FormError } from '@/types/error'
import {
  FilterParams,
  Invite,
  InviteAcceptance,
} from '@/modules/member/types/invite'

export class InviteService extends CRUDService implements ICRUDService<Invite> {
  static async getInvites(
    {
      customRoles,
      defaultRoles,
      emptyRoles,
      inviteRequest,
      pendingInvite,
      listAllPermissions,
    }: FilterParams,
    id: string,
    params?: QueryParams
  ) {
    const { sort, size = '20', page = 0, searchTerm = '' } = params || {}
    return await this.handlePromise<Invite[]>(
      this.api.get<Invite[]>(`/company/${id}/invite?`, {
        params: {
          sort: Array.isArray(sort) ? sort.join('&sort=') : '',
          size,
          page,
          'searchTerm.contains': searchTerm,
          'permissionPreset.custom.equals':
            customRoles || (defaultRoles ? !defaultRoles : ''),
          'permissionPreset.id.specified': emptyRoles ? !emptyRoles : '',
          'inviteRequest.equals':
            inviteRequest || (pendingInvite ? !pendingInvite : ''),
          'permissionPreset.permission.in': listAllPermissions?.length
            ? listAllPermissions.join(',')
            : null,
        },
      })
    )
  }

  static async cancelInvite(corporateId: string, inviteId: number) {
    return await this.handlePromise<Invite[]>(
      this.api.delete<Invite[]>(`/company/${corporateId}/invite/${inviteId}`)
    )
  }

  static async acceptInvite(corporateInviteKey: string) {
    return await this.handlePromise<InviteAcceptance>(
      this.api.post('/company/invite/accept', corporateInviteKey, {
        headers: { 'Content-Type': 'text/plain' },
      })
    )
  }

  static async listInvitePermissions(id: string, inviteId: string) {
    return await this.handlePromise(
      this.api.get<string[]>(`/company/${id}/invite/${inviteId}/permission`)
    )
  }

  static async updateInvitePermissions(
    id: string,
    inviteId: string,
    payload: string[]
  ) {
    return await this.handlePromise<string[], FormError>(
      this.api.put<string[]>(
        `/company/${id}/invite/${inviteId}/permission`,
        payload
      )
    )
  }
  static async assignRoleToInvite(
    id: string,
    inviteId: string,
    roleId: string
  ) {
    return await this.handlePromise(
      this.api.put(
        `/company/${id}/invite/${inviteId}/permission/preset/${roleId}`
      )
    )
  }
}
