import { KeycloakTokenParsed } from 'keycloak-js'
import { keycloakInstance } from '@/security/instance'

type CallbackFunction = (
  input: { user?: KeycloakTokenParsed },
  error?: Error | unknown
) => void

const Login = async (onAuthenticatedCallback: CallbackFunction) => {
  try {
    await keycloakInstance.init({
      onLoad: 'login-required',
    })
    const { tokenParsed: user } = keycloakInstance
    onAuthenticatedCallback({ user })
  } catch (err) {
    console.error('Failed to initialize adapter:', err)
    onAuthenticatedCallback({}, err)
  }
}

const Logout = async () => {
  try {
    await keycloakInstance.logout({
      redirectUri: import.meta.env.VITE_BASE_URL,
    })
  } catch (err) {
    console.error('Failed to logout:', err)
  }
}

export const KeyCloakService = {
  CallLogin: Login,
  Logout,
}

export default KeyCloakService
