import { CRUDService } from '@/services/CRUD'
import { CorporateWithContactData } from '@/types/corporative'
import { FormError } from '@/types/error'
import { ICRUDService } from '@/services/types'

export class CorporateService
  extends CRUDService
  implements ICRUDService<CorporateWithContactData>
{
  static async get(id: number) {
    return await this.handlePromise<CorporateWithContactData>(
      this.api.get(`/company/${id}`)
    )
  }
  static async list() {
    return await this.handlePromise<CorporateWithContactData[]>(
      this.api.get('/company')
    )
  }

  static async create(payload: Partial<CorporateWithContactData>) {
    return await this.handlePromise<CorporateWithContactData, FormError>(
      this.api.post('/company', payload)
    )
  }

  static async requestAccess(id: number) {
    try {
      const response = await this.handlePromise<ResponseType, FormError>(
        this.api.post(`/company/${id}/invite/request`)
      )

      return { data: response, error: undefined }
    } catch (error) {
      return { data: undefined, error }
    }
  }

  static async update(payload: Partial<CorporateWithContactData>) {
    return await this.handlePromise<CorporateWithContactData>(
      this.api.put('/company', payload)
    )
  }

  static async delete(id: number, status: boolean) {
    return await this.handlePromise<number>(
      this.api.delete(`/company/${id}?cancel=${status}`)
    )
  }

  static async getUsageTerms() {
    return this.handlePromise(this.api.get<{ terms: string }>('/company/terms'))
  }

  static async updateCorporateLogo(id: number, file: File) {
    const formData = new FormData()
    formData.append('file', file)
    return await this.handlePromise(
      this.api.put(`company/${id}/logo`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    )
  }

  static async uploadPrivacyPolicy(id: number, file: File) {
    const formData = new FormData()
    formData.append('file', file)
    return await this.handlePromise<number>(
      this.api.put(`/company/${id}/privacy-policy`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    )
  }

  static async deletePrivacyPolicy(id: number) {
    return await this.handlePromise<number>(
      this.api.delete(`/company/${id}/privacy-policy`)
    )
  }

  static async updateOwner(id: number, idUser: string) {
    return await this.handlePromise<number>(
      this.api.put(`/company/${id}/user/${idUser}`)
    )
  }

  static async removeLogoCompany(id: number) {
    return await this.handlePromise<number>(
      this.api.delete(`/company/${id}/logo`)
    )
  }
}
