<script setup lang="ts">
import { Item } from '@/router/links'
import { computed } from 'vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { sidebarItemIcons } from '@/components/Sidebar/sidebar-item-icons'

interface ISidebarItem {
  item: Item
  level?: number
  open?: boolean
}

const { item, level = 1, open = true } = defineProps<ISidebarItem>()

const expanded = ref(false)
const hover = ref(false)
const router = useRouter()

function handleClick(event: Event) {
  event.stopPropagation()
  if (item?.children?.length) {
    expanded.value = !expanded.value
    return
  }
  if (item.target) router.push(item?.target)
}

const expandIcon = computed(() =>
  expanded.value ? 'expand_less' : 'expand_more'
)
const itemIcon = computed(() => {
  if (level != 1 || !item?.name) return

  const iconList = sidebarItemIcons[item?.name as keyof typeof sidebarItemIcons]
  return iconList ? iconList[expanded.value || hover.value ? 1 : 0] : null
})
</script>

<template>
  <li
    class="text-subtitle flex min-h-[4rem] w-full flex-col justify-center font-bold"
    @mouseover="hover = true"
    @mouseout="hover = false"
  >
    <div
      id="sidebar-item-title"
      class="flex h-full items-center px-4 text-white opacity-50"
      :class="`bg-grey-${level} text-${
        level <= 2 ? 'white' : 'black'
      } hover:text-green ${
        !itemIcon && ' hover:enabled:border-l-4 hover:enabled:border-green'
      }`"
      disabled="true"
      @click="handleClick"
    >
      <div
        class="group flex min-h-[4rem] w-full items-center"
        :class="open ? 'justify-start' : 'justify-center'"
      >
        <div v-if="itemIcon" class="w-[2rem]">
          <img class="h-full w-full" :src="itemIcon" />
        </div>

        <div
          v-if="open"
          :class="
            itemIcon
              ? 'ml-4'
              : 'border-l-2 border-green pl-4 group-hover:border-white'
          "
          class="w-full' h-full"
          :disable="true"
        >
          {{ $t(`sidebar.${item.name}`) }}
        </div>
      </div>

      <i
        v-if="item.children && open"
        class="material-symbols-outlined w-[2rem]"
        >{{ expandIcon }}</i
      >
    </div>
    <ul
      v-if="open && expanded"
      id="sidebar-item-children"
      class="h-full w-full p-0"
      :class="`bg-grey-${level + 1}`"
      :disabled="true"
    >
      <SidebarItem
        v-for="link in item.children"
        :key="link.name"
        :item="link"
        :level="level + 1"
        :open="open"
        :disable="true"
      />
    </ul>
  </li>
</template>

<style></style>
