import { FormError } from '@/types/error'
import { Axios, AxiosError, AxiosResponse } from 'axios'
import { API } from '@/services'

export abstract class CRUDService {
  static get api(): Axios {
    return API.getInstance()
  }
  static async handlePromise<T, E = FormError>(
    promise: Promise<AxiosResponse<T>>
  ) {
    let data: T | undefined
    let error: E | undefined
    let res: AxiosResponse<T | E>

    try {
      const response = await promise
      const { data: results } = response
      data = results
      res = response
    } catch (err) {
      res = (err as AxiosError<E>).response as AxiosResponse<T | E>
      error = (err as AxiosError<E>).response?.data
      return { data, error, res }
    }
    return { data, error, res }
  }
}
