import { RouteRecordRaw } from 'vue-router'

export const corporativeContactsRoutes: RouteRecordRaw[] = [
  {
    path: '/account/:id/contacts',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: 'corporative',
        name: 'corporative-contacts-list',
        component: () =>
          import('@/modules/corporative-contacts/views/CorporativeList.vue'),
      },
      {
        path: 'corporative/filter',
        name: 'corporative-contacts-filter',
        component: () =>
          import('@/modules/corporative-contacts/views/CorporativeFilter.vue'),
      },
      {
        path: 'corporative/:contactId/edit',
        name: 'corporative-edit-general-data',
        component: () =>
          import(
            '@/modules/corporative-contacts/views/CorporativeEditGeneralData.vue'
          ),
      },
      {
        path: 'corporative/:contactId/edit-relationships',
        name: 'corporative-edit-relationships',
        component: () =>
          import(
            '@/modules/corporative-contacts/views/CorporativeEditRelationships.vue'
          ),
      },
    ],
  },
]
