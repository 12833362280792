<script setup lang="ts">
import { RouterLink, useRouter } from 'vue-router'
import useStore from '@/store/auth'
import { useCorporativeAccount } from '@/store/corporative'
import { useLayout } from '@/composables/useLayout'
import bgCreateCorporativeAccount from '@/assets/images/bg-criar-conta-corporativa.png'
import eveIcon from '@/assets/images/newLogoEve.svg'
import AccountItem from '@/components/Header/AccountItem.vue'

import greenPlusIcon from '@/assets/images/icon-plus-green.png'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import profileIcon from '@/assets/icons/green/perfil.svg'
import settingsIcon from '@/assets/icons/green/configuration.svg'
import logoutIcon from '@/assets/icons/green/exit.svg'
import configurationOptionsWhite from '@/assets/icons/white/configurationOptions.svg'
import configurationOptionsGreen from '@/assets/icons/green/configurationOptions.svg'
import infoOptionsBlack from '@/assets/icons/black/info.svg'
import infoOptionsWhite from '@/assets/icons/white/info.svg'
import emptyStateNoIconCorporate from '@/assets/images/emptyStateNoIconCorporate.png'

import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

const { logout, getUserInfo } = useStore()

const accountCorporativeStore = useCorporativeAccount()
const {
  corporates,
  isUsingCorporateAccount,
  selectedAccount,
  shouldUseEmptyStateLogo,
} = storeToRefs(accountCorporativeStore)

const authStore = useStore()
const { user } = storeToRefs(authStore)

const { switchAccount } = accountCorporativeStore

const router = useRouter()

const { t } = useI18n()
const labelMenuMyProfile = t('header.myProfile')
const labelMenuExit = t('header.exit')
const labelMenuAccessibilityAndLanguage = t('header.accessibilityAndLanguage')
const labelMenuNewCorporativeAccount = t('header.newCorporativeAccount')
const labelMenuCreateCorporativeAccount = t('header.createCorporativeAccount')

const options = [
  {
    target: '/profile',
    icon: profileIcon,
    label: labelMenuMyProfile,
  },

  { target: '', icon: logoutIcon, label: labelMenuExit, action: logout },
]

function handleAction(option: (typeof options)[0], close: () => void) {
  if (option.target) {
    getUserInfo()
    router.push(option.target)
    close()
    return
  }
  if (option.action) option.action()
}

const { isSidebarOpen } = useLayout()

function handleClick(close: () => void, id?: number) {
  switchAccount(Number(id))
  close()
}
</script>

<template>
  <div class="flex px-4">
    <Menu v-slot="{ close }" as="div" class="relative z-30 flex h-full w-full">
      <MenuButton id="user-menu" class="flex w-full items-center text-white">
        <button class="mr-2">
          <img
            src="@/assets/icons/white/notificacao_in_off.svg"
            class="material-symbols-outlined text-2xl text-grey-1"
          />
        </button>
        <div class="flex items-center">
          <div
            class="mx-4 flex h-8 w-8 items-center justify-center rounded-xl bg-white"
          >
            <img
              :src="
                isUsingCorporateAccount
                  ? shouldUseEmptyStateLogo
                    ? emptyStateNoIconCorporate
                    : String(selectedAccount?.logo)
                  : user?.profilePicture
              "
              alt="Foto do usuário ou logo da empresa"
              class="rounded-xl object-contain transition-all"
              :class="{
                'max-h-1/4 max-w-3/4 h-6 w-6': shouldUseEmptyStateLogo,
                'h-8 max-h-full w-8 max-w-full': !shouldUseEmptyStateLogo,
              }"
            />
          </div>

          <div v-if="isSidebarOpen == true" id="user-menu-trigger" class="flex">
            {{
              isUsingCorporateAccount
                ? selectedAccount?.tradingName
                : user?.fullName
            }}
            <i class="material-symbols-outlined">expand_more</i>
          </div>
        </div>
      </MenuButton>
      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="absolute right-0 top-full mt-4 w-[18rem] origin-top-right overflow-y-auto bg-white"
          as="div"
        >
          <MenuItem
            v-for="(option, index) in options"
            :key="option.label"
            as="div"
          >
            <RouterLink
              :id="`user-menu-option-${index}`"
              class="flex gap-1 px-2 py-4 text-sm font-bold"
              :to="option.target"
              @click="handleAction(option, close)"
            >
              <img class="me-2 text-green" :src="option.icon" />

              {{ option.label }}
            </RouterLink>
          </MenuItem>
          <hr class="mx-4" />

          <MenuItem class="flex flex-col p-2" as="div">
            <div class="flex w-full flex-row gap-4">
              <img :src="settingsIcon" alt="" class="w-6" />
              <div class="flex w-full flex-col">
                <span class="text-xs font-bold">{{
                  labelMenuAccessibilityAndLanguage
                }}</span>
                <div class="flex items-baseline gap-2 font-bold">
                  <button disabled class="text-lg !opacity-20">A+</button>
                  <button disabled class="text-sm !opacity-20">A-</button>
                </div>
              </div>
            </div>
          </MenuItem>
          <hr class="mx-4" />

          <div class="max-h-[50vh] overflow-auto">
            <MenuItem id="user-menu-item-personal-account" as="div">
              <RouterLink :to="{ name: 'home' }" @click="handleClick(close)">
                <AccountItem
                  :img="eveIcon"
                  :text="$t('header.personalAccount')"
                />
              </RouterLink>
            </MenuItem>
            <MenuItem
              v-for="(account, index) in corporates"
              :key="index"
              as="div"
            >
              <RouterLink
                :id="`user-menu-item-corporative-account-${index}`"
                :to="{
                  name: 'home-corporative-account',
                  params: { id: account.id },
                }"
              >
                <AccountItem
                  :id="account.id"
                  :img="account.logo as string"
                  :text="account.tradingName"
                  @click="close()"
                >
                  <RouterLink
                    :id="`user-menu-item-corporative-account-${0}-link`"
                    :to="{
                      name: 'detail-corporative-account',
                      params: { id: account.id },
                    }"
                    @click="handleClick(close, account.id)"
                  >
                    <img
                      v-if="account.owner"
                      :src="
                        !isUsingCorporateAccount
                          ? configurationOptionsGreen
                          : configurationOptionsWhite
                      "
                      class="ml-2 mr-4 w-6 rounded-xl"
                    />
                    <img
                      v-else
                      :src="
                        !isUsingCorporateAccount
                          ? infoOptionsBlack
                          : infoOptionsWhite
                      "
                      class="ml-2 mr-4 w-6 rounded-xl"
                    />
                  </RouterLink>
                </AccountItem>
              </RouterLink>
            </MenuItem>
            <MenuItem v-if="corporates?.length" :as="AccountItem">
              <RouterLink
                id="create-corporate-account"
                :to="{ name: 'create-corporative-account' }"
                class="flex items-center"
                :class="isUsingCorporateAccount ? 'bg-corporate' : 'bg-white'"
                @click="close"
              >
                <img
                  :src="greenPlusIcon"
                  alt=""
                  class="mr-4 w-[2rem] rounded-xl"
                />
                <span
                  class="font-bold"
                  :class="
                    isUsingCorporateAccount ? 'text-white' : 'text-grey-2'
                  "
                  >{{ labelMenuNewCorporativeAccount }}</span
                >
              </RouterLink>
            </MenuItem>
            <MenuItem
              v-else
              id="create-first-corporative-account"
              @click="close"
            >
              <RouterLink
                :to="{ name: 'create-corporative-account' }"
                @click="close"
              >
                <div class="relative flex h-[5rem] w-full items-center">
                  <img
                    :src="bgCreateCorporativeAccount"
                    class="absolute left-0 top-0 z-[20] h-full w-full"
                  />

                  <div
                    class="absolute z-[30] w-full self-center py-4 text-center font-bold text-white"
                  >
                    {{ labelMenuCreateCorporativeAccount }}
                  </div>
                </div>
              </RouterLink>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<style scoped>
.img-container {
  width: 40px;
}
</style>
